import React, { useState } from 'react';
import './App.css';
import Header from './components/header/Header';
import Home from './components/home/Home';
import About from './components/about/About';
import Skills from './components/skills/Skills';
import Services from './components/services/Services';
import Testimonials from './components/qualification/Testimonials';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';
import ScrollUp from './components/scrollup/ScrollUp';
import ReactSwitch from 'react-switch';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

const App = () => {
  const [theme, setTheme] = useState('light');
  const toggleTheme = () => {
    setTheme((curr) => (curr === 'light' ? 'dark' : 'light'));
  };
  const { t, i18n } = useTranslation();

  return (
    <main className="main" id={theme}>
      <div className="switch">
        <ReactSwitch
          onChange={toggleTheme}
          checked={theme === 'light'}
          offHandleColor="#fff"
          checkedIcon=""
          uncheckedIcon=""
        />
      </div>
      <Header translator={t} i18n={i18n} />
      <Home translator={t} />
      <About translator={t} />
      <Skills translator={t} />
      <Services translator={t} />
      <Testimonials translator={t} />
      <Contact translator={t} />
      <Footer translator={t} />
      <ScrollUp />
    </main>
  );
};

export default function WrappedApp() {
  return (
    <Suspense fallback="...loading">
      <App />
    </Suspense>
  );
}
