import React from 'react';
import './services.css';
import PersonalWebsite from './PersonalWebsite';
import Ecommerce from './Ecommerce';
import ProcessesAutomation from './ProcessesAutomation';

const Services = ( {translator} ) => {
    return (
        <section className="services section" id="services">
            <h2 className="section__title">
                {translator('Header.Services')}
            </h2>
            <span className="section__subtitle">
            </span>
            <div className="services__container container grid">
                <PersonalWebsite translator={translator}/>
                <Ecommerce translator={translator}/>
                <ProcessesAutomation translator={translator}/>
            </div>
        </section>
    )
}

export default Services
