import React from 'react';

const Data = ({ translator }) => {
  return (
    <div className="home__data">
      <h1 className="home__title">Julián Macera</h1>
      <h3 className="home__subtitle">
        Full Stack Developer | Data Engineering
      </h3>
      <p className="home__description">{translator('Data.Description')}</p>
      {/* <a href="#contact" className="button button--flex">
        {translator('Data.Contact')}
      </a> */}
    </div>
  );
};

export default Data;
