import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import './contact.css';

function Contact({ translator }) {
  const form = useRef();
  const [isSubmitted, setIsSubmitted] = useState(false); // State to track form submission

  const sendEmail = (e) => {
    e.preventDefault();

    setIsSubmitted(true); // Set isSubmitted to true after sending the email

    emailjs
      .sendForm(
        'service_8acto59',
        'template_5a9jz1k',
        form.current,
        'oyoaJ_iR_AnDTOumX'
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    e.target.reset();
  };

  return (
    <section className="contact section" id="contact">
      <h2 className="section__title">{translator('Contact.Title')}</h2>
      <span className="section__subtitle"></span>
      <div className="contact__container container grid">
        <div className="contact__content">
          <div className="contact__info">
            <div className="contact__card">
              <i className="bx bx-mail-send contact__card-icon"></i>
              <h3 className="contact__card-title">Email</h3>
              <span className="contact__card-data">julimacera@gmail.com</span>
            </div>
            <div className="contact__card">
              <i className="bx bxl-whatsapp contact__card-icon"></i>
              <h3 className="contact__card-title">Whatsapp</h3>
              <span className="contact__card-data">+5491141962275</span>
              <a
                href="https://api.whatsapp.com/send?phone=5491141962275&text=Hola%20Julián!%20Necesitaría%20una%20cotización%20para%20..."
                className="contact__button"
                target="blank"
                rel="noreferrer"
              >
                {' '}
                {translator('Contact.CallToAction')}{' '}
                <i className="bx bx-right-arrow-alt contact__button-icon"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="contact__content">
          <form ref={form} onSubmit={sendEmail} className="contact__form">
            <div className="contact__form-div">
              <label className="contact__form-tag">
                {translator('Contact.Form.Name')}
              </label>
              <input
                required
                type="text"
                name="name"
                className="contact__form-input"
                placeholder="Insert your name"
              />
            </div>
            <div className="contact__form-div">
              <label className="contact__form-tag">
                {translator('Contact.Form.Email')}
              </label>
              <input
                type="email"
                name="email"
                className="contact__form-input"
                placeholder="Insert your email"
                required
              />
            </div>
            <div className="contact__form-div contact__form-area">
              <label className="contact__form-tag">
                {translator('Contact.Form.Message')}
              </label>
              <textarea
                name="message"
                cols="30"
                rows="10"
                className="contact__form-input"
                placeholder="Write your message"
                required
              ></textarea>
            </div>
            {!isSubmitted ? (
              <button className="button button--flex">
                {translator('Contact.Form.CallToAction')}
              </button>
            ) : (
              <p>{translator('Contact.Form.MessageSent')}</p>
            )}
          </form>
        </div>
      </div>
    </section>
  );
}

export default Contact;
