import React from 'react';
import './footer.css';

const Footer = ({ translator }) => {
  return (
    <footer className="footer">
      <div className="footer__container container">
        <h1 className="footer__title">Julián Macera</h1>
        <ul className="footer__list">
          <li>
            <a href="#home" className="footer__link">
              {translator('Header.Home')}
            </a>
          </li>
          <li>
            <a href="#about" className="footer__link">
              {translator('Header.About')}
            </a>
          </li>
          <li>
            <a href="#skills" className="footer__link">
              {translator('Header.Skills')}
            </a>
          </li>
          <li>
            <a href="#services" className="footer__link">
              {translator('Header.Services')}
            </a>
          </li>
          <li>
            <a href="#testimonials" className="footer__link">
              {translator('Header.Testimonials')}
            </a>
          </li>
          <li>
            <a href="#contact" className="footer__link">
              {translator('Header.Contact')}
            </a>
          </li>
        </ul>
        <div className="footer__social">
          <a
            href="https://www.linkedin.com/in/jmacera95"
            className="footer__social-link"
            target="_blank"
            rel="noreferrer"
          >
            <i class="bx bxl-linkedin"></i>
          </a>
          <a
            href="https://www.github.com/jmacera95"
            className="footer__social-link"
            target="_blank"
            rel="noreferrer"
          >
            <i class="bx bxl-github"></i>
          </a>
        </div>

        <span className="footer__copy">
          Web Development & Digital Services.
        </span>
      </div>
    </footer>
  );
};

export default Footer;
