import React from 'react';
import { openInNewTab } from '../../utils/utils';

const Ecommerce = ({ translator }) => {
  return (
    <div className="services__content" id="online_shop_service_card">
      <div className="hover-target">
        <h3 className="services__title">
          {translator('Services.Ecommerce.Title')}
        </h3>
        <p className="services__subtitle">
          {translator('Services.Ecommerce.Description')}
        </p>
        <div className="services__box">
          <div className="services__group">
            <div className="services__data">
              <i class="bx bx-badge-check"></i>
              <div>
                <h3 className="services__name">
                  {translator('Services.Ecommerce.SectionsQty')}
                </h3>
              </div>
            </div>
            <div className="services__data">
              <i class="bx bx-badge-check"></i>
              <div>
                <h3 className="services__name">
                  {translator('Services.Ecommerce.WppIntegration')}
                </h3>
              </div>
            </div>
            <div className="services__data">
              <i class="bx bx-badge-check"></i>
              <div>
                <h3 className="services__name">
                  {translator('Services.Ecommerce.Booking')}
                </h3>
              </div>
            </div>
            <div className="services__data">
              <i class="bx bx-badge-check"></i>
              <div>
                <h3 className="services__name">
                  {translator('Services.Ecommerce.SocialMediaIntegration')}
                </h3>
              </div>
            </div>
            <div className="services__data">
              <i class="bx bx-badge-check"></i>
              <div>
                <h3 className="services__name">
                  {translator('Services.Ecommerce.Translator')}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="services__contact">
        <a
          href="https://api.whatsapp.com/send?phone=5491141962275&text=Hola%20Julian!%20Quisiera%20una%20cotización%20para%20una%20tienda%20online."
          target="_blank"
          rel="noreferrer"
          className="contact__button"
        >
          {' '}
          {translator('Services.CallToAction')}
        </a>
      </div>
    </div>
  );
};

export default Ecommerce;
