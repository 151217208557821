import React, {useState} from 'react';
import './testimonials.css';

const Testimonials = ( {translator} ) => {

    const [toggleState, setToggleState] = useState(1);
    const toggleTab = (index) => {
        setToggleState(index);
    };

    return (
        <div>
            <section className="testimonial section" id='testimonials'>
                {/* <h2 className="section__title">
                    {translator('Testimonials.Title')}
                </h2>
                <span className="section__subtitle">
                    {translator('Testimonials.Subtitle')}
                </span> */}
                <div className="testimonial__container container">
                    <div className="testimonial__tabs">
                        <div className= {toggleState === 1 
                            ? "testimonial__button testimonial__active button--flex" : "testimonial__button button--flex"}
                            onClick={() => toggleTab(1)}
                            >
                            <i className="bx bx-user-voice testimonial__icon"></i> 
                            {translator('Testimonials.Title')}
                        </div>
                    </div>
                    <div className="testimonial__sections">
                        <div className={toggleState === 1
                        ? "testimonial__content testimonial__content-active" : "testimonial__content"}
                        onClick={() => toggleTab(1)}
                        >
                            <div className="testimonial__data">
                                <div>
                                    <h3 className="testimonial__title">{translator('Testimonials.Testimonial1.Title')}</h3>
                                    <span className="testimonial__subtitle">{translator('Testimonials.Testimonial1.Person')}</span>
                                    <div className="testimonial__calendar">
                                        <em>"{translator('Testimonials.Testimonial1.Period')}"</em>
                                    </div>
                                </div>
                                <div>
                                    <span className="testimonial__rounder"></span>
                                    <span className="testimonial__line"></span>
                                </div>
                            </div>
                            <div className="testimonial__data">
                                <div></div>
                                <div>
                                    <span className="testimonial__rounder"></span>
                                    <span className="testimonial__line"></span>
                                </div>
                                <div>
                                    <h3 className="testimonial__title">{translator('Testimonials.Testimonial2.Title')}</h3>
                                    <span className="testimonial__subtitle">{translator('Testimonials.Testimonial2.Person')}</span>
                                    <div className="testimonial__calendar">
                                        <em>"{translator('Testimonials.Testimonial2.Period')}"</em>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="testimonial__data">
                                <div>
                                    <h3 className="testimonial__title">{translator('Testimonials.Education.Education3.Title')}</h3>
                                    <span className="testimonial__subtitle">{translator('Testimonials.Education.Education3.Institution')}</span>
                                    <div className="testimonial__calendar">
                                        <i className="uil uil-calendar-alt"></i> {translator('Testimonials.Education.Education3.Period')}
                                    </div>
                                </div>
                                <div>
                                    <span className="testimonial__rounder"></span>
                                    <span className="testimonial__line"></span>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Testimonials
