import React, { useState } from 'react';
import './header.css';
import SpanishFlag from '../../assets/img/spanish-flag.webp';
import BritishFlag from '../../assets/img/british-flag.webp';

function Header({ translator, i18n }) {
  /* CHANGE BACKGROUND HEADER */
  window.addEventListener('scroll', function () {
    const header = document.querySelector('.header');
    // when the scroll is higher than 200 vw heigh,
    // add the scroll-header class to a tag with the header tag
    if (this.scrollY >= 80) header.classList.add('scroll-header');
    else header.classList.remove('scroll-header');
  });
  /* TOGGLE MENU */
  const [Toggle, showMenu] = useState(false);
  const [activeNav, setActiveNav] = useState('#home');
  const locales = {
    en: {
      title: translator('Header.LanguageOptions.English'),
      flagUrl: BritishFlag,
    },
    es: {
      title: translator('Header.LanguageOptions.Spanish'),
      flagUrl: SpanishFlag,
    },
  };

  return (
    <header className="header">
      <nav className="nav container">
        <a href="index.html" className="nav__logo">
          {translator('Header.Slogan')}
        </a>
        <div className={Toggle ? 'nav__menu show-menu' : 'nav__menu'}>
          <ul className="nav__list grid">
            <li className="nav__item">
              <a
                href="#home"
                onClick={() => setActiveNav('#home')}
                className={
                  activeNav === '#home' ? 'nav__link active-link' : 'nav__link'
                }
              >
                <i className="uil uil-estate nav__icon"></i>
                {translator('Header.Home')}
              </a>
            </li>
            <li className="nav__item">
              <a
                href="#about"
                onClick={() => setActiveNav('#about')}
                className={
                  activeNav === '#about' ? 'nav__link active-link' : 'nav__link'
                }
              >
                <i className="uil uil-user nav__icon"></i>
                {translator('Header.About')}
              </a>
            </li>
            <li className="nav__item">
              <a
                href="#skills"
                onClick={() => setActiveNav('#skills')}
                className={
                  activeNav === '#skills'
                    ? 'nav__link active-link'
                    : 'nav__link'
                }
              >
                <i className="uil uil-file-bookmark-alt nav__icon"></i>
                {translator('Header.Skills')}
              </a>
            </li>
            <li className="nav__item">
              <a
                href="#services"
                className={
                  activeNav === '#services'
                    ? 'nav__link active-link'
                    : 'nav__link'
                }
                onClick={() => setActiveNav('#services')}
              >
                <i className="uil uil-shopping-cart nav__icon"></i>
                {translator('Header.Services')}
              </a>
            </li>
            <li className="nav__item">
              <a
                href="#testimonials"
                className={
                  activeNav === '#testimonials'
                    ? 'nav__link active-link'
                    : 'nav__link'
                }
                onClick={() => setActiveNav('#testimonials')}
              >
                <i className="uil uil-megaphone nav__icon"></i>
                {translator('Header.Testimonials')}
              </a>
            </li>
            <li className="nav__item">
              <a
                href="#contact"
                className={
                  activeNav === '#contact'
                    ? 'nav__link active-link'
                    : 'nav__link'
                }
                onClick={() => setActiveNav('#contact')}
              >
                <i className="uil uil-message nav__icon"></i>
                {translator('Header.Contact')}
              </a>
            </li>
            <li className="nav__item">
              <div className="nav__language__dropdown">
                <select
                  value={i18n.resolvedLanguage}
                  onChange={(e) => i18n.changeLanguage(e.target.value)}
                  className="nav__language__dropdown__selector"
                >
                  {Object.keys(locales).map((locale) => (
                    <option
                      className="nav__language__dropdown__option"
                      key={locale}
                      value={locale}
                    >
                      {locales[locale].title}
                    </option>
                  ))}
                </select>
                <img
                  src={locales[i18n.resolvedLanguage].flagUrl}
                  alt={`${locales[i18n.resolvedLanguage].title} flag`}
                  className="nav__language__dropdown__img"
                />
              </div>
            </li>
          </ul>
          <i
            class="uil uil-times nav__close"
            onClick={() => showMenu(!Toggle)}
          ></i>
        </div>
        <div className="nav__toggle" onClick={() => showMenu(!Toggle)}>
          <i class="uil uil-apps"></i>
        </div>
      </nav>
    </header>
  );
}

export default Header;
