import React from 'react'

const Info = ( {translator} ) => {
    return (
        <div className="about__info grid">
            <div className="about__box">
                <i class='bx bx-award about__icon'></i>
                <h3 className="about__title">{translator('Info.Experience')}</h3>
                <span className="about__subtitle">{translator('Info.ExperienceYears')}</span>
            </div>
            <div className="about__box">
                <i class='bx bx-briefcase-alt about__icon' ></i>
                <h3 className="about__title">{translator('Info.Completed')}</h3>
                <span className="about__subtitle">{translator('Info.CompletedProjects')}</span>
            </div>
            <div className="about__box">
                <i class='bx bx-support about__icon' ></i>
                <h3 className="about__title">{translator('Info.Support')}</h3>
                <span className="about__subtitle">{translator('Info.SupportSchedule')}</span>
            </div>
        </div>
    )
}

export default Info
