import React from 'react'

const ProcessesAutomation = ( {translator} ) => {
    return (
        <div className="services__content" id="processes_automation_service_card">
            <h3 className="services__title">{translator('Services.ProcessesAutomation.Title')}</h3>
            <p className="services__subtitle">{translator('Services.ProcessesAutomation.Description')}</p>
            <div className="services__box">
                <div className="services__group">
                    <div className="services__data">
                        <i class='bx bx-badge-check' ></i>
                        <div>
                            <h3 className="services__name">{translator('Services.ProcessesAutomation.WppBot')}</h3>
                        </div>
                    </div>
                    <div className="services__data">
                        <i class='bx bx-badge-check' ></i>
                        <div>
                            <h3 className="services__name">{translator('Services.ProcessesAutomation.TelegramBot')}</h3>
                        </div>
                    </div>
                    <div className="services__data">
                        <i class='bx bx-badge-check' ></i>
                        <div>
                            <h3 className="services__name">{translator('Services.ProcessesAutomation.DataIntegration')}</h3>
                        </div>
                    </div>
                    <div className="services__data">
                        <i class='bx bx-badge-check' ></i>
                        <div>
                            <h3 className="services__name">{translator('Services.ProcessesAutomation.APIs')}</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="services__contact">
                <a href="https://api.whatsapp.com/send?phone=5491141962275&text=Hola%20Julian!%20Quisiera%20una%20cotización%20para%20automatizar%20procesos." target="_blank" rel="noreferrer" className="contact__button"> {translator('Services.CallToAction')}
                </a>
            </div>
        </div>
    )
}

export default ProcessesAutomation
