import React from 'react';
import './skills.css';

const Skills = ({ translator }) => {
  const skills = [
    { name: 'Python', level: 85 },
    { name: 'SQL', level: 90 },
    { name: 'ReactJS', level: 70 },
    { name: 'Javascript', level: 75 },
    { name: 'HTML/CSS', level: 80 },
    { name: 'NodeJS', level: 60 },
    { name: 'GCP', level: 70 },
    { name: 'Airflow', level: 80 },
  ];

  return (
    <section className="skills section" id="skills">
      <h2 className="section__title">{translator('Header.Skills')}</h2>
      <span className="section__subtitle"></span>
      <div className="skills__container container grid">
        <div className="skills__intro">
          <h3 className="skills__intro__title">
            {translator('Skills.IntroTitle')}
          </h3>
          <p className="skills__intro__detail">
            {translator('Skills.IntroDetail')}
          </p>
        </div>
        <div className="skills__items">
          {skills.map((skill, index) => (
            <div key={index} className="skill-item">
              <div className="skill__header">
                <h4
                  style={{ width: `${skill.level - 7}%` }}
                  className="skill-name"
                >
                  {skill.name}
                </h4>
                <span>{skill.level}%</span>
              </div>
              <div className="progress-bar">
                <div
                  className="progress-bar-value"
                  style={{ width: `${skill.level}%` }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Skills;
