import React from 'react';
import './about.css';
import AboutImg from '../../assets/img/about.jpeg';
import CV from '../../assets/files/CV.pdf';
import Info from './Info';

const About = ({ translator }) => {
  return (
    <section className="about section" id="about">
      <h2 className="section__title">{translator('About.title')}</h2>
      <span className="section__subtitle">
        {translator('About.introduction')}
      </span>
      <div className="about__container container grid">
        <img src={AboutImg} alt="" className="about__img" />
        <div className="about__data">
          <Info translator={translator} />
          <p className="about__description">
            {translator('About.Description')}
          </p>
          <a download="" href={CV} className="button button--flex">
            {translator('About.Resume')}
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
